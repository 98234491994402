import React from "react";
import Container from "../ui/Container";

const SectionThree = () => {
  return (
    <section className="relative py-12 md:py-24 bg-gradient-1 overflow-hidden">
      <Container>
        <h2 className="mb-20 font-heading font-semibold text-center text-4xl md:text-5xl text-gray-900">
          Discover the Power of Glintbot
        </h2>
        <div className="flex flex-wrap -m-8 max-w-4xl mx-auto">
          <div className="w-full md:w-1/2 p-8 border-b md:border-r border-gray-200">
            <div className="flex flex-wrap -m-4">
              <div className="w-auto p-4">
                <img
                  className="filter-hue"
                  src="/assets/images/icon-1.webp"
                  alt="Sniper Bot"
                />
              </div>

              <div className="flex-1 p-4">
                <h3 className="mb-3 font-heading font-bold text-gray-900 text-2xl group-hover:underline">
                  Sniper Bot
                </h3>
                <p className="text-gray-600 text-base">
                  Execute trades with precision and speed. Stay ahead with
                  real-time market analysis.
                </p>
              </div>
            </div>
          </div>
          <div className="w-full md:w-1/2 p-8 border-b border-gray-200">
            <div className="flex flex-wrap -m-4">
              <div className="w-auto p-4">
                <img
                  className="filter-hue"
                  src="/assets/images/icon-2.webp"
                  alt="Wallet Bot"
                />
              </div>

              <div className="flex-1 p-4">
                <h3 className="mb-3 font-heading font-bold text-gray-900 text-2xl group-hover:underline">
                  Wallet Bot
                </h3>
                <p className="text-gray-600 text-base">
                  Securely manage and optimize your digital assets with ease and
                  peace of mind.
                </p>
              </div>
            </div>
          </div>
          <div className="w-full md:w-1/2 p-8 border-b md:border-b-0 md:border-r border-gray-200">
            <div className="flex flex-wrap -m-4">
              <div className="w-auto p-4">
                <img
                  className="filter-hue"
                  src="/assets/images/icon-3.webp"
                  alt="Whale Bot"
                />
              </div>

              <div className="flex-1 p-4">
                <h3 className="mb-3 font-heading font-bold text-gray-900 text-2xl group-hover:underline">
                  Whale Bot
                </h3>
                <p className="text-gray-600 text-base">
                  Track and mimic top traders' moves. Gain a strategic edge with
                  whale strategies.
                </p>
              </div>
            </div>
          </div>
          <div className="w-full md:w-1/2 p-8">
            <div className="flex flex-wrap -m-4">
              <div className="w-auto p-4">
                <img
                  className="filter-hue"
                  src="/assets/images/icon-4.webp"
                  alt="Buy Bot"
                />
              </div>

              <div className="flex-1 p-4">
                <h3 className="mb-3 font-heading font-bold text-gray-900 text-2xl group-hover:underline">
                  Buy Bot
                </h3>
                <p className="text-gray-600 text-base">
                  Automate purchases for smarter investing. Never miss a
                  lucrative opportunity.
                </p>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default SectionThree;
