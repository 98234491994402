import React from "react";
import Container from "../ui/Container";

const SectionTwo = () => {
  return (
    <>
      <section id="sniper" className="relative py-12 md:py-24 overflow-hidden">
        <Container>
          <figure className="grid grid-cols-1 md:grid-cols-2 gap-10 xl:gap-16 items-center">
            <div className="col">
              <div className="max-w-lg">
                <h5 className="text-lg">Glintbot</h5>
                <h1 className="mb-6 text-5xl md:text-6xl font-bold">
                  Sniper Bot
                </h1>
                <h2 className="text-xl lg:text-2xl font-regular">
                  Execute precision trades with speed and accuracy. The Glintbot
                  Sniper Bot identifies and capitalizes on market opportunities
                  in real-time. Perfect for those who want to stay ahead of the
                  curve in the fast-paced world of digital trading.
                </h2>
              </div>
            </div>
            <div className="col">
              <img
                src="/assets/images/bot-image-1.png"
                alt="Glintbot Sniper Bot"
                className="w-full h-full rounded-xl aspect-square"
              />
            </div>
          </figure>
        </Container>
      </section>

      <section
        id="wallet"
        className="relative bg-gradient-to-tl to-[#2A2E45] from-[#4a320e] text-white py-12 md:py-24 overflow-hidden"
      >
        <Container>
          <figure className="grid grid-cols-1 md:grid-cols-2 gap-10 xl:gap-16 items-center">
            <div className="col">
              <div className="max-w-lg">
                <h5 className="text-lg">Glintbot</h5>
                <h1 className="mb-6 text-5xl md:text-6xl font-bold">
                  Wallet Bot
                </h1>
                <h2 className="text-xl lg:text-2xl font-regular">
                  Secure and manage your digital assets effortlessly. The
                  Glintbot Wallet Bot ensures your portfolio is always optimized
                  and protected, providing you with peace of mind and greater
                  control over your investments.
                </h2>
              </div>
            </div>
            <div className="col md:order-first">
              <img
                src="/assets/images/bot-image-2.png"
                alt="Glintbot Sniper Bot"
                className="w-full h-full rounded-xl aspect-square"
              />
            </div>
          </figure>
        </Container>
      </section>

      <section id="whale" className="relative py-12 md:py-24 overflow-hidden">
        <Container>
          <figure className="grid grid-cols-1 md:grid-cols-2 gap-10 xl:gap-16 items-center">
            <div className="col">
              <div className="max-w-lg">
                <h5 className="text-lg">Glintbot</h5>
                <h1 className="mb-6 text-5xl md:text-6xl font-bold">
                  Whale Bot
                </h1>
                <h2 className="text-xl lg:text-2xl font-regular">
                  Track and mimic the moves of the market's biggest players. The
                  Glintbot Whale Bot gives you an edge by following whale
                  strategies, allowing you to benefit from the insights and
                  actions of the top traders in the industry.
                </h2>
              </div>
            </div>
            <div className="col">
              <img
                src="/assets/images/bot-image-3.png"
                alt="Glintbot Sniper Bot"
                className="w-full h-full rounded-xl aspect-square"
              />
            </div>
          </figure>
        </Container>
      </section>

      <section
        id="buybot"
        className="relative bg-gradient-to-tl to-[#2A2E45] from-[#4a320e] text-white py-12 md:py-24 overflow-hidden"
      >
        <Container>
          <figure className="grid grid-cols-1 md:grid-cols-2 gap-10 xl:gap-16 items-center">
            <div className="col">
              <div className="max-w-lg">
                <h5 className="text-lg">Glintbot</h5>
                <h1 className="mb-6 text-5xl md:text-6xl font-bold">Buy Bot</h1>
                <h2 className="text-xl lg:text-2xl font-regular">
                  Simplify your trading process with automated purchases. The
                  Glintbot Buy Bot streamlines your investment strategy with
                  smart buying, ensuring you never miss a lucrative opportunity
                  in the dynamic digital asset market.
                </h2>
              </div>
            </div>
            <div className="col md:order-first">
              <img
                src="/assets/images/bot-image-4.png"
                alt="Glintbot Sniper Bot"
                className="w-full h-full rounded-xl aspect-square"
              />
            </div>
          </figure>
        </Container>
      </section>
    </>
  );
};

export default SectionTwo;
