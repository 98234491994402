import React from "react";
import Container from "../ui/Container";

const botData = [
  {
    href: "#sniper",
    imageUrl: "/assets/images/icon-1.webp",
    name: "Sniper Bot",
    description: "Glintbot",
  },
  {
    href: "#wallet",
    imageUrl: "/assets/images/icon-2.webp",
    name: "wallet Bot",
    description: "Glintbot",
  },
  {
    href: "#whale",
    imageUrl: "/assets/images/icon-3.webp",
    name: "whale Bot",
    description: "Glintbot",
  },
  {
    href: "#buybot",
    imageUrl: "/assets/images/icon-4.webp",
    name: "buy Bot",
    description: "Glintbot",
  },
];

const HeroSection = () => {
  return (
    <section id="hero-section" className="relative text-white">
      <span className="absolute top-0 left-0 h-full w-full">
        <video
          autoPlay
          playsInline
          loop
          muted
          className="h-full w-full object-cover"
          preload="none"
          poster="/assets/images/poster-video.png"
        >
          <source src="/assets/videos/bg-hero-video.mp4" type="video/mp4" />
        </video>
      </span>
      <div className="relative bg-[#472e08ba] pb-20 pt-24">
        <Container>
          <div>
            <div className="lg:py-8">
              <div className="text-center mx-auto max-w-2xl">
                <h1 className="mb-6 text-5xl md:text-6xl xl:leading-tight xl:text-7xl font-bold">
                  The Rising Star of Telegram Trading
                </h1>
                <h2 className="text-xl lg:text-2xl font-regular">
                  Leading the DeFi evolution across Ethereum, BSC, Solana, and
                  beyond. Redefining digital asset trading with unparalleled
                  tools.
                </h2>
              </div>
            </div>
          </div>
          <ul className="hidden lg:grid grid-cols-2 xl:grid-cols-4 gap-5 pt-6">
            {botData.map((bot, index) => (
              <li key={index}>
                <a
                  className="bg-[#ffc772] rounded-[7px] bot-box px-6 py-3 flex flex-row gap-x-5 items-center w-full hover:bg-gradient-to-b hover:from-[#ffbd59] hover:to-[#704f1d] spin-wrapper"
                  href={bot.href}
                >
                  <img
                    src={bot.imageUrl}
                    alt="logo box"
                    className="spin-logo h-12 w-12 filter-hue"
                  />
                  <div className="flex flex-col">
                    <p className="text-black/60 text-sm">{bot.description}</p>
                    <h2 className="text-black text-3xl font-semibold capitalize">
                      {bot.name}
                    </h2>
                  </div>
                </a>
              </li>
            ))}
          </ul>

          <div className="flex flex-wrap gap-6 mt-16 items-center justify-center rounded-[25px] chain-box p-4 w-fit mx-auto">
            <h1 className="w-full text-3xl text-white font-bold text-center">
              Supported Chains
            </h1>
            <hr className="border-[#ffffff30] w-full mb-3 border-[2px]" />
            <img
              className="h-16 w-16 object-contain"
              src="/assets/images/b-icon-1.webp"
              alt="..."
            />
            <img
              className="h-16 w-16 object-contain"
              src="/assets/images/b-icon-2.webp"
              alt="..."
            />
            <img
              className="h-16 w-16 object-contain"
              src="/assets/images/b-icon-3.webp"
              alt="..."
            />
            <img
              className="h-16 w-16 object-contain"
              src="/assets/images/b-icon-4.webp"
              alt="..."
            />
            <img
              className="h-16 w-16 object-contain"
              src="/assets/images/b-icon-5.webp"
              alt="..."
            />
            <img
              className="h-16 w-16 object-contain"
              src="/assets/images/b-icon-6.webp"
              alt="..."
            />
            <img
              className="h-16 w-16 object-contain"
              src="/assets/images/b-icon-7.webp"
              alt="..."
            />
            <img
              className="h-16 w-16 object-contain"
              src="/assets/images/b-icon-8.webp"
              alt="..."
            />
          </div>
        </Container>
      </div>
    </section>
  );
};

export default HeroSection;
