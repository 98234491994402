import React from "react";
import { Link } from "react-router-dom";
import Container from "../ui/Container";

const Footer = () => {
  return (
    <footer className="bg-black text-white pt-10 pb-6">
      <Container>
        <div className="space-y-4">
          <div className="flex justify-between items-center">
            <div>
              <Link to="/" className="flex items-center">
                <img
                  className="h-16 w-auto"
                  src="/assets/images/logo.png"
                  alt="..."
                />
                <span className="text-white font-black uppercase text-2xl">
                  Glintbot
                </span>
              </Link>
            </div>
            <div className="flex space-x-4">
              <a
                href="https://x.com/glint_bot"
                rel="noopener noreferrer"
                target="_blank"
                className="hover:opacity-80"
              >
                <img
                  className="h-8 w-8 invert"
                  src="/assets/images/twitter.png"
                  alt="..."
                />
              </a>
            </div>
          </div>
          <div className="text-xs lg:text-sm font-normal text-gray-200 pl-5">
            The rising star of Telegram trading, Glintbot is leading the DeFi
            evolution across Ethereum, BSC, Solana, and beyond. Glintbot is
            redefining digital asset trading with unparalleled tools, offering
            users a seamless and efficient trading experience. By integrating
            advanced features and intuitive interfaces, it empowers traders to
            navigate the complexities of decentralized finance with ease. As a
            pioneer in this space, Glintbot continues to innovate, setting new
            standards for the industry and driving the adoption of DeFi
            solutions. Its commitment to excellence and user satisfaction makes
            it a top choice for traders seeking cutting-edge technology and
            robust trading capabilities.
          </div>
        </div>
        <div className="mt-4 text-center text-sm">
          Copyright © {/* */}2024{/* */} Glintbot
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
