import React from "react";
import HeroSection from "../components/sections/HeroSection";
import SectionTwo from "../components/sections/SectionTwo";
import SectionThree from "../components/sections/SectionThree";

const HomePage = () => {
  return (
    <main>
      <HeroSection />
      <SectionTwo />
      <SectionThree />
    </main>
  );
};

export default HomePage;
